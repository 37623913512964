import React from 'react';
import { formatThreatLabel, getCtype } from '../../../utils/text';

export const NotificationSummary = ({ category, subcategory, description }:
    { category: string, subcategory: string, description: string }) => {
    const ctype = getCtype(subcategory);
    const threatLabel = getThreatLabel(subcategory);
    switch (category) {
        case 'overall':
            return <span>Significant increase in <b>content volume</b> detected.</span>;
        case 'threats':
            return <span>Significant increase in <b>threat volume</b> detected.</span>;
        case 'threat_types':
            if (!threatLabel) {
                return <span>The number of <b>threats</b> has increased.</span>;
            }
            return <span>The number of <b>{threatLabel} threats</b> has increased.</span>;
        case 'sentiments':
            return <span>The number of <b>{subcategory} documents</b> has increased.</span>;
        case 'ctypes':
            return (
                <span>
                    Significant increase in
                    {ctype.icon && (
                        <i className={`font-size-24 fa ${ctype.icon} ml-1 mr-11 align-middle`}
                            style={
                                ctype.FontFamily
                                    ? { fontFamily: `"${ctype.FontFamily}"`, color: `rgb(${ctype.color})` }
                                    : { color: `rgb(${ctype.color})` }
                            }
                        />
                    )}
                    <b className="ml-11">{ctype.name} content</b> detected.
                </span>
            );
        default:
            return <span>{description}</span>;
    }
};

export const getThreatLabel = (label: string) => {
    const threatLabel = formatThreatLabel(label);
    if (!threatLabel) return null;
    if (label === 'deep-toxic-classifier') {
        return 'Toxic';
    }
    if (threatLabel.name === 'Custom threat') {
        return 'Custom';
    }
    return threatLabel.name;
};
