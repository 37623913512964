import React, { useState } from 'react';
import { Paperclip } from 'react-feather';
import { Button, FormGroup, Input, InputGroup } from 'reactstrap';
import { getRoom } from '../../../utils/variables';
import { Loading } from '../../../components/Loading';
import { getToast } from '../../../utils/getToast';
import { color } from '../../../utils/getColors';
import { useFileUploadSignedURL } from '../../../services/google/getFileUploadSignedURL';

export const CSV = () => {
    const toast = getToast();
    const [file, setFile] = useState(null);
    const [fileUploadLoading, setLoading] = useState(false);
    const fileUploadChange = (e) => setFile(e.target.files[0]);
    const room = getRoom();

    const { getSignedUrl, loading: signedUrlLoading } = useFileUploadSignedURL();
    const uploadFile = async () => {
        try {
            const signedUrl = await getSignedUrl({
                filename: `${room.project_id}_${file.name}`,
                contentType: file.type,
                feature: 'csvUpload'
            });
            if (!signedUrl.data?.getFileUploadSignedURL) {
                toast.error('File upload failed, please try again');
                return;
            }
            setLoading(true);
            const res = await fetch(signedUrl.data?.getFileUploadSignedURL, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            });
            setLoading(false);
            if (res.ok) {
                setFile(null);
                toast.success('File uploaded');
            } else {
                toast.error('File upload failed, please try again');
            }
        } catch {
            toast.error('File upload failed, please try again');
        }
    };
    const loading = fileUploadLoading || signedUrlLoading;
    return (
        <div className="bg-white p-3">
            <h3 className="m-0">Upload CSV</h3>
            <hr className="my-3" />
            <p>Upload your Brandwatch CSV</p>
            <br />
            <InputGroup className="d-flex align-items-center mb-3">
                <FormGroup>
                    <span>
                        <label className="mb-0 upload-file cursor-pointer d-flex align-items-center">
                            <Paperclip color={color.blue[500]} size={15} className="mr-11" />
                            <Input onChange={fileUploadChange}
                                type="file"
                                accept=".csv"
                                className="d-none"
                                data-testid="file-upload"
                            />
                            <b className="text-primary">Choose file</b>
                        </label>
                    </span>
                </FormGroup>
                <span className="ml-2 d-inline-block rounded">{file ? file.name : 'No file choosen'}</span>
            </InputGroup>
            <div className="d-flex align-items-center">
                <Button color="primary" disabled={!file || loading} onClick={() => uploadFile()} className="mr-3">Upload</Button>
                {loading && <div><Loading relative /></div>}
            </div>
        </div>
    );
};
