import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Cluster, SubCluster } from '../../types';
import { IconButton } from '../../../../../components/Atoms';
import { TopLanguage } from './Languages';
import { WidgetName, WidgetTextTooltip } from '../../SelectedClusterView/Summary/WidgetTextTooltip';

type TopLanguagesProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    clusterData: SubCluster[] | Cluster[];
};

export const TopLanguages = ({ isCollapsed, setCollapsable, clusterData }: TopLanguagesProps) => (
    <>
        <IconButton
            className="icon-button text-decoration-none"
            icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
            onClick={setCollapsable}
            text={<WidgetTextTooltip widgetName={WidgetName.Languages} noMargin className="ml-2" />}
            dataTestid="topLanguagesContainer"
        />
        <div className="grid_container_narrative mb-4 mt-3">
            {
                isCollapsed && [0, 1, 2, 3].map((_, index) => {
                    if (clusterData[index]) { return (
                        <TopLanguage topLanguages={clusterData[index].languages} key={clusterData[index].id} />
                    ); }
                    return (
                        <div className="border p-2" key={_} />
                    );
                })
            }
        </div>
    </>
);
