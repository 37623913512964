import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '../../../../components/Tabs';
import { Summary } from './Summary';
import { TopKeywords } from './TopKeywords';
import { SubClusters } from './SubClusters';
import { TopActors } from './TopActors';
import { TopSources } from './TopSources';
import { RootState } from '../../../../store';
import { ClusterOrSubCluster, ExtractCluster } from '../types';
import { OriginalPoster } from './OriginalPoster';
import { TopLocationMentions } from './TopLocationMentions';
import { LoadingState as TotalMatches } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { ClusterContent } from './ClusterContent';
import { findElementByStringFromArray } from '../../../../utils/lib/findElementByStringInNestedArray';
import { getParam } from '../../../../utils/urlParams';
import { checkGrammer } from '../../../../utils/text/checkGrammer';
import { TopLanguages } from './TopLanguages';

export const TabsContainer = <T extends boolean>({ cluster, isParent, allClusterIds, subClustersLoading }: ClusterOrSubCluster<T>) => {
    const [setTab, setChangeTabFn] = useState<(a: string) => void>(() => undefined);
    const parentClusterDetails = findElementByStringFromArray(allClusterIds?.narrativeTheme || [], getParam('narrative') || '');
    const contentIdLength = isParent
        ? cluster.subClusters?.length > 1
        : cluster.contentIds?.length > 1;
    const tabs = [
        {
            name: 'Summary',
            id: 'summary',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : (
                    <Summary cluster={cluster as ExtractCluster<typeof cluster>}
                        isParent={isParent}
                        setTab={setTab}
                        parentClusterDetails={parentClusterDetails}
                    />
                )
        },
        ...(isParent ? [
            {
                name: 'Narratives',
                id: 'narrative-clusters',
                content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                    : <SubClusters clusterData={cluster as ExtractCluster<typeof cluster>} loading={Boolean(subClustersLoading)} />
            }
        ] : []),
        {
            name: checkGrammer('Content', contentIdLength),
            id: 'content',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : (
                    <ClusterContent cluster={cluster as ExtractCluster<typeof cluster>}
                        isParent={isParent}
                        loading={Boolean(subClustersLoading)}
                    />
                )
        },
        ...(!isParent ? [
            {
                name: 'Original poster',
                id: 'original-poster',
                content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                    : <OriginalPoster originatorPostId={cluster?.originatorPostId} />
            }
        ] : []),
        {
            name: 'Actors',
            id: 'top-actors',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : <TopActors topActors={cluster.topActors || []} />
        },
        {
            name: 'Sources',
            id: 'top-sources',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : <TopSources topSources={cluster.topSources || []} />
        },
        {
            name: 'Keywords',
            id: 'top-keywords',
            onlyRenderWhenActive: true,
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></> : <TopKeywords topKeywords={cluster.keyphrases} />
        },
        {
            name: 'Location mentions',
            id: 'top-location-mentions',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : <TopLocationMentions topLocations={cluster.locationMentions || []} />
        },
        {
            name: 'Languages',
            id: 'top-languages',
            content: (!cluster?.id) ? <><br /><TotalMatches items={['a', 'b']} /></>
                : <TopLanguages topLanguauges={cluster?.languages || []} />
        }
    ];
    const { activeTab } = useSelector((state: RootState) => state.activeTab);
    return (
        <Tabs className="bg-white p-3 pt-3 pb-3 mb-0"
            prevKey={activeTab}
            small
            data={tabs}
            goToTab={(fn) => setChangeTabFn(() => fn)}
            tabContentClass={`flex-1 ${!cluster?.id ? 'px-2' : ''}
            border-top bg-white scrollbar-small overflow-y-auto overflow-x-hidden`}
            param="tab"
        />
    );
};
