import React from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SelectAllCheckBox } from '../../Form/CheckBox';
import { ContentTableViewFooter } from './ContentTableViewFooter';
import { ContentTableViewItem } from './ContentTableViewItem';
import { Content as ContentType } from '../../../services/Content/contentInterface';
import { ContentId } from '../../../pages/Room/AIClustering/types';
import { RootState } from '../../../store';
import { setSelectedContent } from '../../../pages/Room/store';

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

export type ContentTableContentType = ContentType;

type ContentTableViewProps = {
    contents: ContentTableContentType[]
    selectedContentItems: ContentTableContentType[]
    total: number
    pagination?: PaginationType
    allowSelectBulkActions: boolean
    noActionsAllowed: boolean
    bulkContentsSelected?: boolean
    onSelectAll: () => void
    onClearSelection: () => void
    onBulkContentsSelected?: (value: boolean) => void
    onSetSelectedContentItems: (values: ContentTableContentType[]) => void
    onRemoveContent?: (data: { id: string }[]) => void
    onMarkAsThreat?: (id: string) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    onRequestInvestigation?: (content: ContentTableContentType) => void
    onBatchContentRemove?: () => void
    isAINarratives?: boolean
    clusterData?: ContentId[]
}

export const ContentTableView = ({
    contents,
    selectedContentItems,
    pagination,
    allowSelectBulkActions,
    noActionsAllowed,
    bulkContentsSelected,
    total,
    onSelectAll,
    onClearSelection,
    onBulkContentsSelected,
    onSetSelectedContentItems,
    onRemoveContent,
    onMarkAsThreat,
    onMarkAsNotThreat,
    onRequestInvestigation,
    onBatchContentRemove,
    isAINarratives = false,
    clusterData
}: ContentTableViewProps) => {
    const dispatch = useDispatch();
    const { selectedContent, bulkContent, removedContent } = useSelector((state: RootState) => state.selectedContent);
    const handleAllCheck = (options: ContentTableContentType[]) => {
        onSetSelectedContentItems(options as ContentTableContentType[]);
        const contentIds = options.map((a) => a.id);
        if (bulkContent && (options.length === contents.length)) {
            const finalOptions = removedContent.filter((a: string) => !contentIds.includes(a));
            return dispatch(setSelectedContent({ removedContent: [...finalOptions] }));
        } if (bulkContent && options.length === 0) {
            const finalOptions = removedContent.filter((a: string) => !contentIds.includes(a));
            return dispatch(setSelectedContent({ removedContent: [...finalOptions, ...(contents.map((a) => a.id))] }));
        } if (options.length === 0) {
            const finalOptions = selectedContent.filter((a: string) => !contentIds.includes(a));
            return dispatch(setSelectedContent({ selectedContent: [...finalOptions] }));
        }
        const finalOptions = selectedContent.filter((a: string) => !contentIds.includes(a));
        return dispatch(setSelectedContent({ selectedContent: [...finalOptions, ...contentIds] }));
    };
    if ((pagination && !pagination.count) || (!pagination && !contents.length)) return null;

    return (
        <>
            <div className="bg-white overflow-x-auto">
                <Table className={`m-0 ${isAINarratives ? 'border-right border-left' : ''}`}>
                    <thead>
                        <tr>
                            {!noActionsAllowed && (
                                <td className="pr-0" data-testid="select-all-content">
                                    <SelectAllCheckBox id="allThreatsContent"
                                        displayLabel={false}
                                        options={contents}
                                        values={selectedContentItems}
                                        allCheck={(options) => handleAllCheck(options as ContentTableContentType[])}
                                        page="content-table"
                                    />
                                </td>
                            )}
                            <td className={noActionsAllowed ? 'pl-2' : 'pl-1'}>Select all</td>
                        </tr>
                    </thead>
                    <tbody data-testid="content-list-items">
                        {contents.map((content) => (
                            <ContentTableViewItem key={content.id}
                                content={content}
                                selectedContentItems={selectedContentItems}
                                noActionsAllowed={noActionsAllowed}
                                onSetSelectedContentItems={onSetSelectedContentItems}
                                onRemoveContent={onRemoveContent}
                                onMarkAsThreat={onMarkAsThreat}
                                onMarkAsNotThreat={onMarkAsNotThreat}
                                onRequestInvestigation={onRequestInvestigation}
                                clusterData={clusterData}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
            <ContentTableViewFooter selectedContentItems={selectedContentItems}
                pagination={pagination}
                bulkContentsSelected={bulkContentsSelected}
                allowSelectBulkActions={allowSelectBulkActions}
                total={total}
                onSelectAll={onSelectAll}
                onBulkContentsSelected={onBulkContentsSelected}
                onRemoveContent={onRemoveContent}
                clearSelection={onClearSelection}
                onMarkAsNotThreat={onMarkAsNotThreat}
                onBatchContentRemove={onBatchContentRemove}
                contentCount={contents.length || 0}
            />
        </>
    );
};
