import React from 'react';
import { UrlMentions } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';

type UrlMentionProps = {
    topUrlMentions: UrlMentions[]
}

export const UrlMention = ({ topUrlMentions }: UrlMentionProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], url: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={url} />,
        size: 'xl'
    });
    return (
        <div className="bg-white border p-2">
            {
                !topUrlMentions ? <p className="m-0">No data found</p>
                    : topUrlMentions.map((urls, index) => {
                        if (index > 4) return null;
                        return (
                            <div key={urls.url} className={index !== 0 ? 'mt-1' : ''}>
                                <p className="btn btn-link d-inline p-0 dont-break-out"
                                    onClick={() => openDuplictedDocsModal(urls.contentIds, urls.url)}
                                >{urls.url}
                                </p> - {urls.contentIds.length}
                            </div>
                        ); })
            }
        </div>
    );
};
